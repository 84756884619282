import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

export function useProposalHookForm(user) {
  let formSchema = z.object({
    name: z.string().min(1, {
      message: "O nome do contratante é obrigatório"
    }),
    email: z.string().min(1, {
      message: "O email do contratante é obrigatório"
    }),
    type_event: z.string().min(1, {
      message: "O tipo do evento é obrigatória"
    }),
    date_event: z.string().min(1, {
      message: "A data do evento é obrigatória"
    }),
    date1_event: z.string().optional(),
    date2_event: z.string().optional(),
    time_event: z.string().min(1, {
      message: "O horário do evento é obrigatório"
    }),
    local: z.string().min(1, {
      message: "O local do evento é obrigatório"
    }),
    quantity: z.coerce.number().min(1, {
      message: "Quantidade de pessoas é obrigatório"
    }),
    event: z.string().min(1, {
      message: "O local do evento é obrigatório"
    }),
    obs: z.string().optional(),
    city: z.string().min(1, {
      message: "A cidade do evento é obrigatória"
    }),
    state: z.string().min(1, {
      message: "O estado do evento é obrigatório"
    }),
  })

  if (user) {
    formSchema = formSchema.extend({
      name: z.string().min(1, {
        message: "O nome do contratante é obrigatório"
      }),
      email: z.string().min(1, {
        message: "O email do contratante é obrigatório"
      }).email({
        message: "O email do contratante deve ser válido"
      }),
    });
  }


  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "Valor Padrão do Nome",
      email: "email@example.com",
      type_event: "",
      date_event: "",
      date1_event:"",
      date2_event: "",
      time_event: "",
      local: "",
      quantity: "",
      event: "",
      obs: "",
      city: "",
      state: ""
    }
  })

  return { register, handleSubmit, errors, formSchema }
}