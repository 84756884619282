import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { POST } from "../../services/api.ts";

function PagesLogin() {
	const context = useContext(AppSettings);
	const [userInfo, setUserInfo] = useState(false);
	const [redirect, setRedirect] = useState(false);

	const [email, setEmail] = useState("");
	const [pass, setPass] = useState("");
	const [idUser, setIdUser] = useState("");
	const [temporaryPass, setTemporaryPass] = useState(false);
	const [badCredentials, setBadCredentials] = useState(false);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass("p-0");

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass("");
		};

		// eslint-disable-next-line
	}, []);

	function handleSubmit(event) {
		event.preventDefault();
		login();
		//setRedirect(true);
	}

	const login = async () => {
		try {
			var options = {
				method: "POST",
				url: "http://localhost:7005/user/login",
				headers: {
					Accept: "*/*",
					"Content-Type": "application/json",
				},
				data: {
					username: email,
					password: pass,
				},
			};
			// const res = await axios.request(options);
			const res = await POST("user/login", {
				username: email,
				password: pass,
			});

			localStorage.setItem("loginInfo_access", JSON.stringify(res));
			setUserInfo(res);
			setRedirect(true);
			if(res.payload.temporaryPass === true){
				setTemporaryPass(true);
				setIdUser(res.payload.id);
			}			
		} catch (error) {
			console.log(error);
			setBadCredentials(true);
		}
	};

	if (redirect && userInfo) {
		if(temporaryPass){
			return <Navigate to={`../new-password/${idUser}`}  />;
		}else{
			return <Navigate to="/proposals" />;

		}	
	}

	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center">Entrar</h1>
					<div className="text-inverse text-opacity-50 text-center mb-4">
						Para sua segurança, verifique sua identidade.
					</div>
					<div className="mb-3">
						<label className="form-label">
							Endereço de Email{" "}
							<span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder=""
							onChange={(event) => setEmail(event.target.value)}
						/>
					</div>
					<div className="mb-3">
						<div className="d-flex">
							<label className="form-label">
								Senha <span className="text-danger">*</span>
							</label>
							{/* <a
								href="#/"
								className="ms-auto text-inverse text-decoration-none text-opacity-50"
							>
								Esqueceu a senha?
							</a> */}
							<Link
								to="/pages/recover"
								className="ms-auto text-inverse text-decoration-none text-opacity-50"
								tabIndex={-1}
							>
								Esqueceu a senha?
							</Link>
						</div>
						<input
							type="password"
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder=""
							onChange={(event) => setPass(event.target.value)}
						/>
					</div>
					{badCredentials && (
						<div className="mb-3">
							<span className="text-danger">
								Email ou senha incorretos
							</span>
						</div>
					)}
					<div className="mb-3">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								id="customCheck1"
								tabIndex={-1}
							/>
							<label
								className="form-check-label"
								htmlFor="customCheck1"
							>
								Lembrar de mim
							</label>
						</div>
					</div>
					<button
						type="submit"
						className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
					>
						Entrar
					</button>
					<div className="text-center text-inverse text-opacity-50">
						Ainda não tem uma conta?{" "}
						<Link to="/pages/register">Cadastrar-se</Link>.
					</div>
				</form>
			</div>
		</div>
	);
}

export default PagesLogin;
