
import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye} from '@fortawesome/free-solid-svg-icons';
import { POST } from "../../services/api.ts";
import { useParams } from "react-router-dom";

function ChangePassword() {
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
    const { id } = useParams();
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
	const [badCredentials, setBadCredentials] = useState(false);
    const [enableConfirm, setEnableConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const eye = <FontAwesomeIcon icon={faEye} />;
    const [showOldPass, setShowOldPass] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
  
    const toggleVisibilityOldPass = () => setShowOldPass(!showOldPass);
    
  
    const toggleVisibilityPass = () => setShowPass(!showPass);

  
    const toggleVisibilityConfirmPass = () => setShowConfirmPass(!showConfirmPass);
  

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass("p-0");

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass("");
		};

		// eslint-disable-next-line
	}, []);
    
    useEffect(() => {
        validateForm();

    // eslint-disable-next-line 

    }, [oldPass, newPass, confirmPass]);


    const validateForm = () => {
        if (oldPass.trim() !== "" && newPass.trim() !== "" && confirmPass.trim() !== "") {
            setEnableConfirm(true);
        } else {
            setEnableConfirm(false);
        }
    };

	function handleSubmit(event) {
		event.preventDefault();
        if (newPass === confirmPass) {
            changePassword();
        } else {
            setErrorMessage("As senhas não coincidem.");
        }
	}

	const changePassword = async () => {
		try {
             await POST("user/changePassword", {
				id,
                newPass,
                oldPass
			});
		} catch (error) {
            setBadCredentials(true);
		}
        setRedirect(true)
	};

	if (redirect ) {

		return <Navigate to="/proposals" />;
	}

	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center">Alterar Senha</h1>
					<div className="mb-3 position-relative ">
						<label className="form-label">
							Senha Atual
							<span className="text-danger">*</span>
						</label>
                        <div className="d-flex align-items-center">
                            <input
                                type={showOldPass ? 'text' : 'password'}
                                className="form-control form-control-lg bg-white bg-opacity-5"
                                placeholder=""
                                onChange={(event) => setOldPass(event.target.value)}
                            />               
                            <button
                                tabIndex={-1}
                                variant="outline-secondary"
                                onClick={toggleVisibilityOldPass}
                                style={{ marginLeft: '5px' }}
                                className="btn btn-outline-secondary "
                            >
                                <i>{eye}</i>
                            </button>
                        </div>
					</div>
					<div className="mb-3">
							<label className="form-label">
								Nova Senha 
                                <span className="text-danger">*</span>
							</label>						
                        <div className="d-flex align-items-center">
                            <input
                                type={showPass ? 'text' : 'password'}
                                className="form-control form-control-lg bg-white bg-opacity-5"
                                placeholder=""
                                onChange={(event) => setNewPass(event.target.value)}
                            />
                             <button
                                tabIndex={-1}
                                variant="outline-secondary"
                                onClick={toggleVisibilityPass}
                                style={{ marginLeft: '5px' }}
                                className="btn btn-outline-secondary "
                            >
                                <i>{eye}</i>
                            </button>
					    </div>
                    </div>
                    <div className="mb-3">						
							<label className="form-label">
								Confirmar Nova Senha 
                                <span className="text-danger">*</span>
							</label>
                        <div className="d-flex align-items-center">
                            <input
                               type={showConfirmPass ? 'text' : 'password'}
                                className="form-control form-control-lg bg-white bg-opacity-5"
                                placeholder=""
                                onChange={(event) => setConfirmPass(event.target.value)}
                                /> 
                            <button
                                tabIndex={-1}
                                variant="outline-secondary"
                                onClick={toggleVisibilityConfirmPass}
                                style={{ marginLeft: '5px' }}
                                className="btn btn-outline-secondary "
                            >
                                <i>{eye}</i>
                            </button>
                        </div>    
					</div>
                    {errorMessage && (
                        <div className="mb-3">
                            <span className="text-danger">{errorMessage}</span>
                        </div>
                    )}
                    {badCredentials && (
						<div className="mb-3">
							<span className="text-danger">
								 Senha incorreta
							</span>
						</div>
					)}
					<button
						type="submit"
						className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" 
                        disabled={!enableConfirm}                 
					>
						Salvar
					</button>
				</form>
			</div>
		</div>
	);
}

export default ChangePassword;
