import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GET, POST, PUT , getImageUrl} from "../services/api.ts";
import { Card, CardBody } from "../components/card/card";
import { Modal } from "bootstrap";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment/locale/pt-br";
import "./iauu.css";
import { Toast } from "bootstrap";
import DataTable from "datatables.net-dt";
import "./iauu.css";
import { FiMessageCircle } from "react-icons/fi";

const $ = require("jquery");
$.DataTable = require("datatables.net");

function Proposals() {
	let user = {
		...JSON.parse(localStorage.getItem("loginInfo_access")).payload,
	};
	const [proposalsData, setProposalsData] = useState([]);
	const [proposals, setProposals] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalData, setModalData] = useState(false);
	const [modal, setModal] = useState();
	const [modalBandData, setModalBandData] = useState(false);
	const [modalBand, setModalBand] = useState();
	const [bandFail, setBandFail] = useState(false);
	const [proposalUpdates, setProposalUpdates] = useState({});
	const [pdfBase64, setPdfBase64] = useState(null);
	const [imageBase64, setImageBase64] = useState(null);
	const [emailError, setEmailError] = useState(false);
	const [statusProposal, setStatusProposal] = useState("Todos");
	const [rows, setRows] = useState([]);
	const table = new DataTable("#datatableDefault");
	const navigate = useNavigate()

	moment.locale("pt-br");

	useEffect(() => {
		setModal(new Modal(document.getElementById("modalPosItem")));
		fetchProposals();
		new DataTable("#datatableDefault", {
			lengthMenu: [10, 20, 30, 40, 50],
			responsive: true,
			language: {
				url: "//cdn.datatables.net/plug-ins/1.13.6/i18n/pt-BR.json",
			},
			autoWidth: false,
			buttons: ["print", "csv"],
		});
		return function cleanUp() {
			$(".dataTables_wrapper table").DataTable().destroy(true);
		};
	}, []);

	$("#btn-place").html(table.buttons().container());

	table.on("click", "tr", function (event) {
		const currentId = parseInt(event.currentTarget.cells[0].innerText);
		if (currentId) {
			const row = proposals.find((item) => item.id === currentId);
			setModalData(row);
			setProposalUpdates({ editor_id: user.id });
			modal.show();
		}
	});

	useEffect(() => {
		if (modal) {
			modal._element.addEventListener("hidden.bs.modal", () => {
				setProposalUpdates(false);
				setPdfBase64(null);
			});
		}

		return () => {
			if (modal) {
				modal._element.removeEventListener("hidden.bs.modal", () => {
					setProposalUpdates(false);
					setPdfBase64(null);
				});
			}
		};
	}, [modal]);

	useEffect(() => {
		if (proposals.length > 0) {
			if (user.type === "admin") {
				const rowsData = proposals.map((item) => [
					// `<input type="checkbox" class="form-check-input" id="product${index}" />`,
					`<a href="#">${item.id}</a>`,
					new Date(item.date_event).toLocaleDateString("pt-BR"),
					new Date(item.created).toLocaleDateString("pt-BR"),
					item.band[0].name,
					item.user[0].name,
					`R$${item.proposal.toLocaleString("pt-BR")},00`,
					`<span class="badge border border-${statusColor(
						item.status
					)} text-${statusColor(
						item.status
					)} px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i class="fa fa-circle fs-9px fa-fw me-5px"></i>${
						item.status
					}</span>`,
				]);

				setRows(rowsData)
			} else {
				console.log(proposals)
				const rowsData = proposals.map((item) => [
					// `<input type="checkbox" class="form-check-input" id="product${index}" />`,
					`<a href="#">${item.id}</a>`,
					new Date(item.date_event).toLocaleDateString("pt-BR"),
					new Date(item.created).toLocaleDateString("pt-BR"),
					item.band[0].name,
					`R$${item.proposal.toLocaleString("pt-BR")},00`,
					`<span class="badge border border-${statusColor(
						item.status
					)} text-${statusColor(
						item.status
					)} px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i class="fa fa-circle fs-9px fa-fw me-5px"></i>${
						item.status
					}</span>`,
				]);

				setRows(rowsData)
			}
		}
	}, [proposals, statusProposal]);

	useEffect(() => {
		console.log(rows)
		addNewRows(rows);
	}, [rows])

	function addNewRows(newRows) {
		console.log(newRows)
		if (newRows.length > 0) {
			table.clear().draw();
			newRows.forEach((row) => {
				table.row.add(row).draw(false);
			});
		}
	}

	const fetchProposals = async () => {
		const userAgentBandIds = await GET("/user/getUser/" + user.id).then((res) => res.band.map((band) => band.id));
		try {
			let data = [];
			if (user.type === "contractor") {
				data = await POST("/proposal/filter", {
					user_id: user.id,
					state: statusProposal,
				});
			} else if (user.type === "admin") {
				data = await POST("/proposal/filter", {});
			} else if (user.type === "agent" || user.type === "band") {
				data = await POST("/proposal/filter", {
					band_ids: userAgentBandIds,
					state: statusProposal,
				});
			}
			setProposalsData(data.data);
			console.log('propostas', data.data)
			setProposals(data.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	function statusColor(status) {
		if (status === "Realizada") {
			return "warning";
		} else if (status === "Aceita" || status === "Assinado") {
			return "success";
		} else if (status === "Em Assinatura" || status === "Aguardando Pagamento") {
			return "primary";
		} else {
			return "red";
		}
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setProposalUpdates((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleBandInputChange = (event) => {
		if (event.target.type === 'tel') {
			let input = event.target
			input.value = phoneMask(input.value)
		}

		const { name, value } = event.target;
		setModalBandData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const phoneMask = (value) => {
		if (!value) return ""
		value = value.replace(/\D/g,'')
		value = value.replace(/(\d{2})(\d)/,"($1) $2")
		value = value.replace(/(\d)(\d{4})$/,"$1-$2")
		return value
	}

	const submitUpdates = async () => {
		if (Object.keys(proposalUpdates).length > 1) {
			try {
				await PUT(`/proposal/${modalData.id}`, proposalUpdates);
				await fetchProposals();
				setModalData(false);
				modal.hide();
				const toast1 = document.getElementById("update-success");
				const toast = new Toast(toast1);
				toast.show();
			} catch (error) {
				console.log(error);
			}
		}
	};

	const submitContract = async () => {
		if (pdfBase64) {
			try {
				const contractData = {
					bandEmail: modalData.band[0].email,
					contractorEmail: modalData.user[0].email,
					proposalId: modalData.id,
					pdf: pdfBase64,
				};
				const newContract = await POST(
					"/proposal/contract",
					contractData
				);
				if (newContract.success === true) {
					await PUT(`/proposal/${modalData.id}`, {
						status: "Em Assinatura",
					});
					await fetchProposals();
					setModalData(false);
					modal.hide();
					const toast1 = document.getElementById("contract-success");
					const toast = new Toast(toast1);
					toast.show();
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const submitBand = async () => {
		setBandFail(false);
		if (Object.keys(modalBandData).length === 8 && user.type === "contractor") {
			try {
				const contractor = await POST("/user/band", {
					...modalBandData,
					id: user.id,
				})
				if (contractor.error === 409) {
					setEmailError(true);
					return;
				}else {		
					modalBand.hide();	           			
					const updatedUser = {
						...JSON.parse(localStorage.getItem("loginInfo_access")),
						payload: {...user, contractorId: true}  
					};
					user = updatedUser;
					localStorage.setItem(
						"loginInfo_access",
						JSON.stringify(updatedUser)
						);
					setModalBandData(false);
				}				
			} catch (error) {
				console.log(error);
			}
		} else {
			setBandFail(true);
		}
	};

	const handlePdfChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const base64Data = e.target.result;
				setPdfBase64(base64Data);
			};
			reader.readAsDataURL(file);
		}
	};

	const uploadImage = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const base64Data = e.target.result;
				setImageBase64(base64Data);			
			};
			await reader.readAsDataURL(file);
		}
	};

	const renderButton = () => {
		if (modalData.status === "Aguardando Pagamento" && user.type === "contractor") {
			return (
				<div className="row">
					<div className="">
						<Link
							onClick= {() => modal.hide()}
							state={modalData}
							className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0" 
							to={`/payment/${modalData.id}`}
						>
							Pagar
						</Link>
					</div>
				</div>
			);
		} else if (pdfBase64) {
			return (
				<div className="row">
					<div className="">
						<button
							onClick={submitContract}
							className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
						>
							Enviar Contrato
						</button>
					</div>
				</div>
			);
		} else if (modalData.status === "Assinado" && (user.type === "agent" || user.type === "admin")) {
			return (
				<div className="row">
					<div className="">
						<Link
							onClick= {() => modal.hide()}
							state={modalData}
							className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0" 
							to={`/payment/${modalData.id}`}
						>
							Definir Forma de Pagamento
						</Link>
					</div>
				</div>
			);
		} else if (modalData.status === "Realizada") {
			return (
				<div className="row">
					<div className="">
						<button
							onClick={submitUpdates}
							className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
						>
							Atualizar
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className="row">
					<div className="">
						<button className="btn btn-danger w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0">
							Atualização Indisponível
						</button>
					</div>
				</div>
			);
		}
	};

	if (modalBand && user.type === "contractor" && !user.contractorId) {
		modalBand.show();
	}

	function handleCloseModal() {
		setModalData(false)
		modal?.hide()
		modalBand?.hide();
	}

	async function handleCreateChat(e) {
		console.log(user.type === "agent" ? modalData.band[0].userId : modalData.user[0].id)
		const otherUserId = user.type === "agent" ? modalData.user[0].id : modalData.band[0].userId;
		POST("/chat", {
			user_ids: [user.id, otherUserId],
			proposal_id: modalData.id,
			message: "Olá, tudo bem?"
		})
		.then(() => {
			handleCloseModal()
			navigate(`/iauu/chat?proposal_id=${modalData.id}`)
		});
	}

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Propostas</h1>
				</div>
				{
					user.type === "contractor" && (
						<div className="ms-auto">
							<a href="/vitrine" className="btn btn-outline-theme">
								<i className="fa fa-plus-circle fa-fw me-1"></i> Nova Proposta
							</a>
						</div>
					)
				}
			</div>

			<div className="mb-md-4 mb-3 d-md-flex">
				<div id="btn-place"></div>
				{/* <div className="mt-md-0 mt-2"><a href="#/" className="text-inverse text-opacity-75 text-decoration-none"><i className="fa fa-download fa-fw me-1 text-theme"></i> Exportar</a></div>
				<div className="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
					<a href="#/" data-bs-toggle="dropdown" className="text-inverse text-opacity-75 text-decoration-none">Mais Opções</a>
					<div className="dropdown-menu">
						<a className="dropdown-item" href="#/">Action</a>
						<a className="dropdown-item" href="#/">Another action</a>
						<a className="dropdown-item" href="#/">Something else here</a>
						<div role="separator" className="dropdown-divider"></div>
						<a className="dropdown-item" href="#/">Separated link</a>
					</div>
				</div> */}
			</div>

			<Card>
				<ul className="nav nav-tabs nav-tabs-v2 px-4">
					<li className="nav-item me-3">
						<button
							className="nav-link active px-2"
							data-bs-toggle="tab"
							onClick={() => 
								setStatusProposal("Todos")
							}
						>
							Todas
						</button>
					</li>
					<li className="nav-item me-3">
						<button
							className="nav-link px-2"
							data-bs-toggle="tab"
							onClick={() =>
								setStatusProposal("Realizada")
							}
						>
							Realizadas
						</button>
					</li>
					<li className="nav-item me-3">
						<button
							className="nav-link px-2"
							data-bs-toggle="tab"
							onClick={() => 
								setStatusProposal("Aceita")
							}
						>
							Aceitas
						</button>
					</li>
					<li className="nav-item me-3">
						<button
							className="nav-link px-2"
							data-bs-toggle="tab"
							onClick={() =>
								setStatusProposal("Cancelada")
							}
						>
							Canceladas
						</button>
					</li>
				</ul>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active">
						{/* <div className="input-group mb-4">
							<div className="flex-fill position-relative">
								<div className="input-group">
									<input 
										type="text" 
										className="form-control px-35px" 
										placeholder="Filtrar"
										onChange={(input) => setProposals(
											proposalsData.filter((item) => item.band[0].name.toLowerCase().includes(input.target.value.toLowerCase())))}
									/>
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 1020}}>
										<i className="fa fa-search opacity-5"></i>
									</div>
								</div>
							</div>
							<button className="btn btn-outline-default dropdown-toggle rounded-0" type="button" data-bs-toggle="dropdown"><span className="d-none d-md-inline">Status do Pagamento</span><span className="d-inline d-md-none"><i className="fa fa-credit-card"></i></span> &nbsp;</button>
							<div className="dropdown-menu">
								<a className="dropdown-item" href="#/">Action</a>
								<a className="dropdown-item" href="#/">Another action</a>
								<a className="dropdown-item" href="#/">Something else here</a>
								<div role="separator" className="dropdown-divider"></div>
								<a className="dropdown-item" href="#/">Separated link</a>
							</div>
							<button className="btn btn-outline-default dropdown-toggle" type="button" data-bs-toggle="dropdown"><span className="d-none d-md-inline">Status do Show</span><span className="d-inline d-md-none"><i className="fa fa-check"></i></span> &nbsp;</button>
							<div className="dropdown-menu dropdown-menu-end">
								<a className="dropdown-item" href="#/">Action</a>
								<a className="dropdown-item" href="#/">Another action</a>
								<a className="dropdown-item" href="#/">Something else here</a>
								<div role="separator" className="dropdown-divider"></div>
								<a className="dropdown-item" href="#/">Separated link</a>
							</div>
						</div> */}

						<div className="table-responsive">
							<table
								id="datatableDefault"
								className="table table-hover text-nowrap"
							>
								<thead>
									<tr>
										{/* <th className="border-top-0 pt-0 pb-2"></th> */}
										<th className="border-top-0 pt-0 pb-2">
											Proposta
										</th>
										<th className="border-top-0 pt-0 pb-2">
											Data do Show
										</th>
										<th className="border-top-0 pt-0 pb-2">
											Data da Proposta
										</th>
										<th className="border-top-0 pt-0 pb-2">
											Artista
										</th>
										{user.type === "admin" && (
											<th className="border-top-0 pt-0 pb-2">
												Contratante
											</th>
										)}
										<th className="border-top-0 pt-0 pb-2">
											Total
										</th>
										<th className="border-top-0 pt-0 pb-2">
											Status da Proposta
										</th>
									</tr>
								</thead>
								<tbody></tbody>
							</table>
						</div>
					</div>
				</div>
			</Card>

			{/* Modal de atualização da proposta */}

			<div className="modal modal-pos fade" id="modalPosItem">
				<div className="modal-dialog modal-xl">
					<div className="modal-content border-0">
						{modalData && (
							<Card>
								<CardBody className="p-0">
									<button
										data-bs-dismiss="modal"
										onClick={() => handleCloseModal()}
										className="btn-close position-absolute top-0 end-0 m-4"
									>
										&nbsp;
									</button>
									<div className="modal-pos-product">
										<div className="modal-pos-product-img">
											<div
												className="img"
												style={{
													backgroundImage: `url(${getImageUrl(modalData.band[0].id, modalData.band[0].imageType)})`,
													backgroundSize: "cover",
													position: "relative",
												}}
											>
												<div
													className="loader"
													style={{ zIndex: -1 }}
												></div>
											</div>
										</div>
										<div className="modal-pos-product-info">
											<div className="h4 mb-2">
												{modalData.band[0].name}
											</div>
											<div className="text-white text-opacity-50 mb-2">
												{modalData.band[0].styles}
											</div>
											<div className="text-white text-opacity-50 mb-2">
												Sobre a banda
											</div>
											<hr className="mx-n4" />

											{/* Data Principal e Horário				 */}

											<div className="d-flex flex-row mb-2">
												<div className="mb-2 me-4">
													<div className="fw-bold">
														Data Principal
													</div>
													<DateTime
														inputProps={{
															placeholder:
																"Data Principal",
														}}
														closeOnSelect={true}
														dateFormat="DD/MM/YYYY"
														timeFormat={false}
														value={
															proposalUpdates.date_event ||
															new Date(
																modalData.date_event
															).toLocaleDateString(
																"pt-BR"
															)
														}
														onChange={(e) =>
															setProposalUpdates({
																...proposalUpdates,
																date_event:
																	e.toDate(),
															})
														}
													/>
												</div>
												<div className="mb-2">
													<div className="fw-bold">
														Horário
													</div>
													<DateTime
														inputProps={{
															placeholder:
																"Horário",
														}}
														closeOnSelect={true}
														dateFormat={false}
														timeFormat={"HH:mm"}
														value={
															proposalUpdates.time_event ||
															modalData.time_event
																.split(":")
																.slice(0, 2)
																.join(":")
														}
														onChange={(e) =>
															setProposalUpdates({
																...proposalUpdates,
																time_event:
																	e.toDate(),
															})
														}
													/>
												</div>
											</div>

											{/* Datas alternativas */}

											<div className="d-flex flex-row mb-2">
												<div className="mb-2 me-4">
													<div className="fw-bold">
														Data Alternativa 1
													</div>
													<DateTime
														inputProps={{
															placeholder:
																"Data Alternativa 1",
														}}
														closeOnSelect={true}
														dateFormat="DD/MM/YYYY"
														timeFormat={false}
														key={
															proposalUpdates.date1_event ||
															new Date(
																modalData.date1_event
															).getFullYear() <
																2000
																? false
																: new Date(
																		modalData.date1_event
																  ).toLocaleDateString(
																		"pt-BR"
																  )
														}
														value={
															proposalUpdates.date1_event ||
															new Date(
																modalData.date1_event
															).getFullYear() <
																2000
																? false
																: new Date(
																		modalData.date1_event
																  ).toLocaleDateString(
																		"pt-BR"
																  )
														}
														onChange={(e) =>
															setProposalUpdates({
																...proposalUpdates,
																date1_event:
																	e.toDate(),
															})
														}
													/>
												</div>
												<div className="mb-2">
													<div className="fw-bold">
														Data Alternativa 2
													</div>
													<DateTime
														inputProps={{
															placeholder:
																"Data Alternativa 2",
														}}
														closeOnSelect={true}
														dateFormat="DD/MM/YYYY"
														timeFormat={false}
														key={
															proposalUpdates.date2_event ||
															new Date(
																modalData.date2_event
															).getFullYear() <
																2000
																? false
																: new Date(
																		modalData.date2_event
																  ).toLocaleDateString(
																		"pt-BR"
																  )
														}
														value={
															proposalUpdates.date2_event ||
															new Date(
																modalData.date2_event
															).getFullYear() <
																2000
																? false
																: new Date(
																		modalData.date2_event
																  ).toLocaleDateString(
																		"pt-BR"
																  )
														}
														onChange={(e) =>
															setProposalUpdates({
																...proposalUpdates,
																date2_event:
																	e.toDate(),
															})
														}
													/>
												</div>
											</div>

											{/* Tipo de evento e quantidade de pessoas */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="quantity"
														className="fw-bold"
													>
														Quantidade Pessoas
													</label>
													<input
														key={modalData.quantity}
														defaultValue={
															modalData.quantity
														}
														type="number"
														onChange={
															handleInputChange
														}
														name="quantity"
														className="form-control"
														id="quantity"
													></input>
												</div>
												<div
													className="mb-2"
													style={{ flex: 1 }}
												>
													<div className="fw-bold">
														Tipo de Evento
													</div>
													<select
														key={
															modalData.type_event
														}
														onChange={
															handleInputChange
														}
														name="type_event"
														className="form-select"
														defaultValue={
															modalData.type_event
														}
													>
														<option value="Bilhetado">
															Bilhetado
														</option>
														<option value="Privado">
															Privado
														</option>
													</select>
												</div>
											</div>

											{/* Sobre o Evento */}

											<div className="d-flex flex-row mb-2">
												<div className="mb-2 w-100">
													<div className="fw-bold">
														Sobre Evento
													</div>
													<textarea
														key={modalData.event}
														defaultValue={
															modalData.event
														}
														onChange={
															handleInputChange
														}
														name="event"
														rows="3"
														className="form-control"
													></textarea>
												</div>
											</div>

											{/* Estado e Cidade */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<div className="fw-bold">
														Estado
													</div>
													<select
														key={modalData.state}
														onChange={
															handleInputChange
														}
														name="state"
														className="form-select"
														defaultValue={
															modalData.state
														}
													>
														<option value="AC">
															Acre
														</option>
														<option value="AL">
															Alagoas
														</option>
														<option value="AP">
															Amapá
														</option>
														<option value="AM">
															Amazonas
														</option>
														<option value="BA">
															Bahia
														</option>
														<option value="CE">
															Ceará
														</option>
														<option value="DF">
															Distrito Federal
														</option>
														<option value="ES">
															Espírito Santo
														</option>
														<option value="GO">
															Goiás
														</option>
														<option value="MA">
															Maranhão
														</option>
														<option value="MT">
															Mato Grosso
														</option>
														<option value="MS">
															Mato Grosso do Sul
														</option>
														<option value="MG">
															Minas Gerais
														</option>
														<option value="PA">
															Pará
														</option>
														<option value="PB">
															Paraíba
														</option>
														<option value="PR">
															Paraná
														</option>
														<option value="PE">
															Pernambuco
														</option>
														<option value="PI">
															Piauí
														</option>
														<option value="RJ">
															Rio de Janeiro
														</option>
														<option value="RN">
															Rio Grande do Norte
														</option>
														<option value="RS">
															Rio Grande do Sul
														</option>
														<option value="RO">
															Rondônia
														</option>
														<option value="RR">
															Roraima
														</option>
														<option value="SC">
															Santa Catarina
														</option>
														<option value="SP">
															São Paulo
														</option>
														<option value="SE">
															Sergipe
														</option>
														<option value="TO">
															Tocantins
														</option>
													</select>
												</div>
												<div
													className="mb-2"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="city"
														className="fw-bold"
													>
														Cidade
													</label>
													<input
														key={modalData.city}
														defaultValue={
															modalData.city
														}
														onChange={
															handleInputChange
														}
														name="city"
														className="form-control"
														id="city"
													></input>
												</div>
											</div>

											{/* Local e Proposta */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="local"
														className="fw-bold"
													>
														Local
													</label>
													<input
														key={modalData.local}
														defaultValue={
															modalData.local
														}
														onChange={
															handleInputChange
														}
														name="local"
														className="form-control"
														id="local"
													></input>
												</div>
												<div
													className="mb-2"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="proposal"
														className="fw-bold"
													>
														Proposta
													</label>
													<input
														key={modalData.proposal}
														defaultValue={
															modalData.proposal
														}
														onChange={
															handleInputChange
														}
														name="proposal"
														className="form-control"
														id="proposal"
														type="number"
													></input>
												</div>
											</div>

											<div>
												<button
													className="btn btn-lg button-finaly d-block fw-500 mb-3"
													onClick={(e) => handleCreateChat(e)}
												>
													<FiMessageCircle/>
													Enviar mensagem
												</button>
											</div>

											{/* Status da proposta  */}

											{user.type !== "contractor" && (
												<div className="d-flex flex-row mb-2">
													<div
														className="mb-2"
														style={{ flex: 1 }}
													>
														<div className="fw-bold">
															Status da Proposta
														</div>
														<select
															key={
																modalData.status
															}
															onChange={
																handleInputChange
															}
															name="status"
															className="form-select"
															defaultValue={
																modalData.status
															}
														>
															<option value="Realizada">
																Realizada
															</option>
															<option value="Aceita">
																Aceita
															</option>
															<option value="Cancelada">
																Cancelada
															</option>
														</select>
													</div>
												</div>
											)}

											{/* Envio de Contrato */}

											{user.type !== "contractor" &&
												modalData.status ===
													"Aceita" && (
													<div className="d-flex flex-row mb-2">
														<div
															className="mb-2"
															style={{ flex: 1 }}
														>
															<div className="fw-bold">
																Upload de
																Contrato
																(Somente PDF)
															</div>
															<input
																type="file"
																className="form-control form-control-sm"
																id="smFile"
																accept="application/pdf"
																onChange={
																	handlePdfChange
																}
															/>
														</div>
													</div>
												)}

											<hr className="mx-n4" />

											{renderButton()}

											{/* {user.type === "contractor" && modalData.status !== "Realizada" ?
												<div className="row">
													<div className="">
														<button className="btn btn-danger w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0">
															Atualização
															Indisponível
														</button>
													</div>
												</div>
											} */}
										</div>
									</div>
								</CardBody>
							</Card>
						)}
					</div>
				</div>
			</div>

			{/* Modal de atualização da conta  */}

			<div className="modal modal-pos fade" id="modalBandPosItem">
				<div className="modal-dialog modal-xl">
					<div className="modal-content border-0">
						{
							<Card>
								<CardBody className="p-0">
									<button
										data-bs-dismiss="modal"
										className="btn-close position-absolute top-0 end-0 m-4"
									>
										&nbsp;
									</button>
									<div className="modal-pos-band">
										<div className="modal-pos-band-info">
											<div className="h4 mb-2">
												Complete seu Perfil
											</div>
											<div className="text-white text-opacity-50 mb-2">
												{user.type === "agent" ? 
													<div>
														Precisamos de algumas
														informações sobre sua banda para
														prosseguirmos.
														<br />
														Preencha os campos tendo em
														mente que essas informações
														poderão ser visualizadas por
														possíveis clientes.
													</div> : 
													<div>
														Precisamos de algumas
														informações sobre você para
														prosseguirmos.
														<br />
														Preencha os campos tendo em
														mente que essas informações
														serão utilizadas nos contratos aqui fechados.
													</div>
												}												
											</div>
											<hr className="mx-n4" />

											{/* Nome e email */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
													{user.type === "agent" ? 
														<div>
															Nome da Banda
														</div> : 
														<div>
															Nome
														</div>
													}			
													</label>
													<input
														defaultValue={
															modalBandData.name
														}
														onChange={
															handleBandInputChange
														}
														name="name"
														className="form-control"
													></input>
												</div>
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														Email
													</label>
													<input
														defaultValue={
															modalBandData.email
														}
														onChange={
															handleBandInputChange
														}
														name="email"
														className="form-control"
														type="email"
													></input>
												</div>
												<div
													className="mb-2"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														CPF ou CNPJ
													</label>
													<input
														defaultValue={
															modalBandData.document
														}
														onChange={
															handleBandInputChange
														}
														name="document"
														className="form-control"
													></input>
												</div>
											</div>

											{/* Estado e Cidade */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<div className="fw-bold">
														Estado
													</div>
													<select
														key={
															modalBandData.state
														}
														onChange={
															handleBandInputChange
														}
														name="state"
														className="form-select"
														defaultValue={
															modalBandData.state
														}
													>
														<option value="AC">
															Acre
														</option>
														<option value="AL">
															Alagoas
														</option>
														<option value="AP">
															Amapá
														</option>
														<option value="AM">
															Amazonas
														</option>
														<option value="BA">
															Bahia
														</option>
														<option value="CE">
															Ceará
														</option>
														<option value="DF">
															Distrito Federal
														</option>
														<option value="ES">
															Espírito Santo
														</option>
														<option value="GO">
															Goiás
														</option>
														<option value="MA">
															Maranhão
														</option>
														<option value="MT">
															Mato Grosso
														</option>
														<option value="MS">
															Mato Grosso do Sul
														</option>
														<option value="MG">
															Minas Gerais
														</option>
														<option value="PA">
															Pará
														</option>
														<option value="PB">
															Paraíba
														</option>
														<option value="PR">
															Paraná
														</option>
														<option value="PE">
															Pernambuco
														</option>
														<option value="PI">
															Piauí
														</option>
														<option value="RJ">
															Rio de Janeiro
														</option>
														<option value="RN">
															Rio Grande do Norte
														</option>
														<option value="RS">
															Rio Grande do Sul
														</option>
														<option value="RO">
															Rondônia
														</option>
														<option value="RR">
															Roraima
														</option>
														<option value="SC">
															Santa Catarina
														</option>
														<option value="SP">
															São Paulo
														</option>
														<option value="SE">
															Sergipe
														</option>
														<option value="TO">
															Tocantins
														</option>
													</select>
												</div>
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="bandCity"
														className="fw-bold"
													>
														Cidade
													</label>
													<input
														defaultValue={
															modalBandData.city
														}
														onChange={
															handleBandInputChange
														}
														name="city"
														className="form-control"
														id="bandCity"
													></input>
												</div>
												<div
													className="mb-2"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="bandCity"
														className="fw-bold"
													>
														Logradouro
													</label>
													<input
														defaultValue={
															modalBandData.street
														}
														onChange={
															handleBandInputChange
														}
														name="street"
														className="form-control"
														id="bandStreet"
													></input>
												</div>
											</div>

											{/* Telefones e foto */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														Telefone de contato
													</label>
													<input
														defaultValue={
															modalBandData.phone
														}
														onChange={
															handleBandInputChange
														}
														name="phone"
														className="form-control"
														id="phone"
														type="tel"
													></input>
												</div>
												<div
													className="mb-2 me-6"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														Telefone alternativo
													</label>
													<input
														defaultValue={
															modalBandData.phone2
														}
														onChange={
															handleBandInputChange
														}
														name="phone2"
														className="form-control"
														id="phone2"
														type="tel"
													></input>
												</div>
												
											</div>

											{user.type === "agent" ? 
												<div>
													{/* Sobre a Banda */}

													<div className="d-flex flex-row mb-2">
														<div className="mb-2 w-100">
															<div className="fw-bold">
																Breve Descrição da sua
																Banda
															</div>
															<textarea
																// key={modalBandData.about}
																defaultValue={
																	modalBandData.about
																}
																onChange={
																	handleBandInputChange
																}
																rows="3"
																name="about"
																className="form-control"
																required
															></textarea>
														</div>
													</div>

													<div className="mb-2" style={{ flex: 1 }}>
														<label className="fw-bold">Foto da Banda/Artista</label>
														<input
															onChange={uploadImage}
															type="file" 
															className="form-control form-control-lg" 
															id="lgFile" 
															accept="image/*"
															/>
												    </div>
													{/* Estilo, cachê e pix */}

													<div className="d-flex flex-row mb-2">
														<div
															className="mb-2 me-4"
															style={{ flex: 1 }}
														>
															<label className="fw-bold">
																Estilo Musical
															</label>
															<input
																defaultValue={
																	modalBandData.styles
																}
																onChange={
																	handleBandInputChange
																}
																name="styles"
																className="form-control"
																id="styles"
															></input>
														</div>
														<div
															className="mb-2 me-4"
															style={{ flex: 1 }}
														>
															<label className="fw-bold">
																Valor aproximado do seu
																cachê
															</label>
															<input
																defaultValue={
																	modalBandData.cache
																}
																onChange={
																	handleBandInputChange
																}
																name="cache"
																className="form-control"
																id="cache"
																type="number"
															></input>
														</div>
														<div
															className="mb-2"
															style={{ flex: 1 }}
														>
															<label className="fw-bold">
																Chave PIX
															</label>
															<input
																defaultValue={
																	modalBandData.pix
																}
																onChange={
																	handleBandInputChange
																}
																name="pix"
																className="form-control"
																id="pix"
															></input>
														</div>
													</div>	

													{/* Dados bancários */}

													<div className="d-flex flex-row mb-2">
														<div
															className="mb-2 me-4"
															style={{ flex: 1 }}
														>
															<label className="fw-bold">
																Código do banco
															</label>
															<input
																defaultValue={
																	modalBandData.bank
																}
																onChange={
																	handleBandInputChange
																}
																name="bank"
																className="form-control"
																id="bank"
																type="number"
															></input>
														</div>
														<div
															className="mb-2 me-4"
															style={{ flex: 1 }}
														>
															<label className="fw-bold">
																Agência
															</label>
															<input
																defaultValue={
																	modalBandData.agency
																}
																onChange={
																	handleBandInputChange
																}
																name="agency"
																className="form-control"
																id="agency"
																type="number"
															></input>
														</div>
														<div
															className="mb-2"
															style={{ flex: 1 }}
														>
															<label className="fw-bold">
																Número da conta
															</label>
															<input
																defaultValue={
																	modalBandData.account
																}
																onChange={
																	handleBandInputChange
																}
																name="account"
																className="form-control"
																id="account"
																type="number"
															></input>
														</div>
													</div>
												</div>
												:
												null
											}											

											<hr className="mx-n4" />

											<div className="row">
												<div className="">
													{bandFail && (
														<div
															style={{
																color: "#c72e2e",
															}}
														>
															Todos os campos
															devem ser
															preenchidos
														</div>
													)}
													{emailError && (
														<div
															style={{
																color: "#c72e2e",
															}}
														>
															Email cadastrado para outra banda.
														</div>
													)}
													<button
														onClick={submitBand}
														className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
													>
														Atualizar Perfil
													</button>
												</div>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						}
					</div>
				</div>
			</div>

			{/* Notificação de sucesso na atualização da proposta */}

			<div className="toasts-container">
				<div
					className="toast"
					data-autohide="false"
					id="update-success"
				>
					<div className="toast-header">
						<i className="far fa-bell text-muted me-2"></i>
						<strong className="me-auto">Atenção</strong>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="toast"
						></button>
					</div>
					<div className="toast-body">
						Proposta atualizada com sucesso!
					</div>
				</div>
			</div>

			{/* Notificação de sucesso ao enviar contrato */}

			<div className="toasts-container">
				<div
					className="toast"
					data-autohide="false"
					id="contract-success"
				>
					<div className="toast-header">
						<i className="far fa-bell text-muted me-2"></i>
						<strong className="me-auto">Atenção</strong>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="toast"
						></button>
					</div>
					<div className="toast-body">
						Verifique seu Email para assinatura eletrônica!
					</div>
				</div>
			</div>
		</div>
	);
}

export default Proposals;
