import axios from "axios";

// let loginData = localStorage.getItem("loginInfo_access");
const baseURL =
	process.env.REACT_APP_DEBUG === "1"
		? "http://localhost:7005"
		: "https://api.iauu.com.br";

const api = axios.create({
	baseURL: baseURL, //process.env.API_URL,

	// headers: {
	// 	Authorization:
	// 		loginData && typeof loginData === "string"
	// 			? `Bearer ${JSON.parse(loginData).access_token}`
	// 			: "",
	// 	"content-type": "application/json",
	// },
});

// api.defaults.headers.Authorization = `Bearer ${
// 	JSON.parse(loginData).access_token
// }`;

const getImageUrl = (userId, imageType) => {
	return `${baseURL}/files/id/user-${userId}-id.${imageType}`;
  };

console.log("baseURL", baseURL);
console.log("DEBUG", process.env.REACT_APP_DEBUG);

export default api;

const setBearerToken = async (token: string) => {
	api.defaults.headers.Authorization = `Bearer ${token}`;
};

const GET = (url: string | void) =>
	api
		.get(url || "")
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			throw e.response;
		});

const POST = (url: string | void, params: object | Array<any | void> | void) =>
	api
		.post(url || "", params)
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			throw e.response.data;
		});

const PUT = (url: string | void, params: object | Array<any> | void) =>
	api
		.put(url || "", params)
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			throw e.response.data;
		});

const DELETE = (url: string | void) =>
	api
		.delete(url || "")
		.then((res) => res.data)
		.then((res) => {
			return res;
		})
		.catch((e) => {
			throw e.response.data;
		});

export { GET, POST, PUT, DELETE, setBearerToken, getImageUrl };
