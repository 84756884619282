import { Fragment } from "react"

function FormRoot({ children,  formSubmitFn }) {
  return (
    <form
      onSubmit={formSubmitFn}
    >
      { children }
    </form>
  )
}

function GroupSection({ groupName, children }) {
  return (
    <section className="mb-3">
      <h4>{ groupName }</h4>

      { children }

    </section>
  )
}

function GroupField({ labelName, children, isRequired = true }) {
  return (
    <div className="mb-3">
      <label>{ labelName }</label>
      { isRequired && <span className="text-danger">*</span> }
      { children }
    </div>
  )
}

export const Form = {
  Root: FormRoot,
  Group: GroupSection,
  GroupField
}