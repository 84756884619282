import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { POST, GET, PUT , getImageUrl} from "../../services/api.ts";
import { Card, CardBody } from "../../components/card/card";
import { Modal } from "bootstrap";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment/locale/pt-br";
import { Toast } from "bootstrap";
import DataTable from "datatables.net-dt";
import "../iauu.css";
import { FiMessageCircle } from "react-icons/fi";

const $ = require("jquery");
$.DataTable = require("datatables.net");

function Banda() {
	let user = {
		...JSON.parse(localStorage.getItem("loginInfo_access")).payload,
	};
	const [Banda, setBanda] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalData, setModalData] = useState(false);
	const [modal, setModal] = useState();
	const [modalBandData, setModalBandData] = useState(false);
	const [modalBandDataUpdate, setModalBandDataUpdate] = useState(false);
	const [modalBandUpdate, setModalBandUpdate] = useState(false);
	const [modalBand, setModalBand] = useState();
	const [bandFail, setBandFail] = useState(false);
	const [proposalUpdates, setProposalUpdates] = useState({});
	const [pdfBase64, setPdfBase64] = useState(null);
	const [imageBase64, setImageBase64] = useState(null);
	const [emailError, setEmailError] = useState(false);
	const table = new DataTable("#datatableDefault");
	const navigate = useNavigate()

	moment.locale("pt-br");

	useEffect(() => {
		// setModal(new Modal(document.getElementById("modalPosItem")));
		setModalBand(new Modal(document.getElementById("modalBandPosItem")));
		fetchBandas();
		new DataTable("#datatableDefault", {
			lengthMenu: [10, 20, 30, 40, 50],
			responsive: true,
			language: {
				url: "//cdn.datatables.net/plug-ins/1.13.6/i18n/pt-BR.json",
			},
			autoWidth: false,
			// buttons: ["print", "csv"],
		});

		return function cleanUp() {
			$(".dataTables_wrapper table").DataTable().destroy(true);
		};
	}, []);

	$("#btn-place").html(table.buttons().container());

	table.on("click", "tr", function (event) {
		const currentId = parseInt(event.currentTarget.cells[0].innerText);
		console.log(event)
		if (currentId) {
			const row = Banda.find((item) => item.id === currentId);
			setModalBandDataUpdate(row);
			console.log(row)
			setProposalUpdates({ editor_id: user.id });
			modalBand.show();
		}
	});

	// useEffect(() => {
	// 	if (modal) {
	// 		modal._element.addEventListener("hidden.bs.modal", () => {
	// 			setProposalUpdates(false);
	// 			setPdfBase64(null);
	// 		});
	// 	}

	// 	return () => {
	// 		if (modal) {
	// 			modal._element.removeEventListener("hidden.bs.modal", () => {
	// 				setProposalUpdates(false);
	// 				setPdfBase64(null);
	// 			});
	// 		}
	// 	};
	// }, [modal]);

	useEffect(() => {
		if (Banda && Banda.length > 0) {
			let rows;
			if (user.type === "agent") {
				rows = Banda.map((item) => [
					item.id,
					item.name,
          item.style
				]);
			}
			addNewRows(rows);
		}
	}, [Banda]);

	function addNewRows(newRows) {
		if (newRows.length > 0) {
			table.clear().draw();
			newRows.forEach((row) => {
				table.row.add(row).draw(false);
			});
		}
	}

	const fetchBandas = async () => {
		try {
			let data = [];
      data = await GET(`/bands?userId=${user.id}`);
			setBanda(data);
      console.log(data);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	// function statusColor(status) {
	// 	if (status === "Realizada") {
	// 		return "warning";
	// 	} else if (status === "Aceita" || status === "Assinado") {
	// 		return "success";
	// 	} else if (status === "Em Assinatura" || status === "Aguardando Pagamento") {
	// 		return "primary";
	// 	} else {
	// 		return "red";
	// 	}
	// }

	// const handleInputChange = (event) => {
	// 	const { name, value } = event.target;
	// 	setProposalUpdates((prevData) => ({
	// 		...prevData,
	// 		[name]: value,
	// 	}));
	// };

	const handleBandInputChange = (event) => {
		if (event.target.type === 'tel') {
			let input = event.target
			input.value = phoneMask(input.value)
		}

		const { name, value } = event.target;
		if (modalBandDataUpdate) {
			setModalBandDataUpdate((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
		setModalBandData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const phoneMask = (value) => {
		if (!value) return ""
		value = value.replace(/\D/g,'')
		value = value.replace(/(\d{2})(\d)/,"($1) $2")
		value = value.replace(/(\d)(\d{4})$/,"$1-$2")
		return value
	}

	// const submitUpdates = async () => {
	// 	if (Object.keys(proposalUpdates).length > 1) {
	// 		try {
	// 			await PUT(`/proposal/${modalData.id}`, proposalUpdates);
	// 			await fetchBanda();
	// 			setModalData(false);
	// 			modal.hide();
	// 			const toast1 = document.getElementById("update-success");
	// 			const toast = new Toast(toast1);
	// 			toast.show();
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	}
	// };

	// const submitContract = async () => {
	// 	if (pdfBase64) {
	// 		try {
	// 			const contractData = {
	// 				bandEmail: modalData.band[0].email,
	// 				contractorEmail: modalData.user[0].email,
	// 				proposalId: modalData.id,
	// 				pdf: pdfBase64,
	// 			};
	// 			const newContract = await POST(
	// 				"/proposal/contract",
	// 				contractData
	// 			);
	// 			if (newContract.success === true) {
	// 				await PUT(`/proposal/${modalData.id}`, {
	// 					status: "Em Assinatura",
	// 				});
	// 				await fetchBanda();
	// 				setModalData(false);
	// 				modal.hide();
	// 				const toast1 = document.getElementById("contract-success");
	// 				const toast = new Toast(toast1);
	// 				toast.show();
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	}
	// };

	const submitBand = async () => {
		setBandFail(false);
		if (Object.keys(modalBandData).length === 15 && user.type === "agent" && imageBase64) {
			try {	 
				const band = await POST("user/band", {
					...modalBandData,
					image: imageBase64,
					id: user.id,
				})
        fetchBandas();
				if (band.error === 409) {
					setEmailError(true);
					return;
				}else{
					modalBand.hide();
					setModalBandData(false);
        }
			} catch (error) {
				console.log(error);
			}
		} else {
			setBandFail(true);
		}
	};

	const updateBand = async () => {
		setBandFail(false);
		console.log(modalBandDataUpdate)
		if (Object.keys(modalBandDataUpdate).length === 20 && user.type === "agent") {
			try {	 
				const band = await PUT("user/band", {
					...modalBandDataUpdate,
					image: imageBase64,
				})
        fetchBandas();
				if (band.error === 409) {
					setEmailError(true);
					return;
				}else{
					modalBand.hide();
					setModalBandDataUpdate(false);
        }
			} catch (error) {
				console.log(error);
			}
		} else {
			setBandFail(true);
		}
	};

	// const handlePdfChange = (event) => {
	// 	const file = event.target.files[0];
	// 	if (file) {
	// 		const reader = new FileReader();
	// 		reader.onload = (e) => {
	// 			const base64Data = e.target.result;
	// 			setPdfBase64(base64Data);
	// 		};
	// 		reader.readAsDataURL(file);
	// 	}
	// };

	const uploadImage = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const base64Data = e.target.result;
				setImageBase64(base64Data);			
			};
			await reader.readAsDataURL(file);
		}
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Minhas Bandas</h1>
				</div>

				<div className="ms-auto">
					<button onClick={() => {modalBand.show(); setModalBandDataUpdate(false)}} className="btn btn-outline-theme">
						<i className="fa fa-plus-circle fa-fw me-1"></i> Criar nova Banda
					</button>
				</div>
			</div>

			<div className="mb-md-4 mb-3 d-md-flex">
				<div id="btn-place"></div>
				{/* <div className="mt-md-0 mt-2"><a href="#/" className="text-inverse text-opacity-75 text-decoration-none"><i className="fa fa-download fa-fw me-1 text-theme"></i> Exportar</a></div>
				<div className="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
					<a href="#/" data-bs-toggle="dropdown" className="text-inverse text-opacity-75 text-decoration-none">Mais Opções</a>
					<div className="dropdown-menu">
						<a className="dropdown-item" href="#/">Action</a>
						<a className="dropdown-item" href="#/">Another action</a>
						<a className="dropdown-item" href="#/">Something else here</a>
						<div role="separator" className="dropdown-divider"></div>
						<a className="dropdown-item" href="#/">Separated link</a>
					</div>
				</div> */}
			</div>

			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active">

						<div className="table-responsive">
							<table
								id="datatableDefault"
								className="table table-hover text-nowrap"
							>
								<thead>
									<tr>
										<th className="border-top-0 pt-0 pb-2">
											Banda
										</th>
										<th className="border-top-0 pt-0 pb-2">
											Nome da Banda
										</th>
                    <th className="border-top-0 pt-0 pb-2">
											Estilo Musical
										</th>
									</tr>
								</thead>
								<tbody></tbody>
							</table>
						</div>
					</div>
				</div>
			</Card>

			{/* Modal de atualização da conta  */}

			<div className="modal modal-pos fade" id="modalBandPosItem">
				<div className="modal-dialog modal-xl">
					<div className="modal-content border-0">
						{
							<Card>
								<CardBody className="p-0">
									<button
										data-bs-dismiss="modal"
										className="btn-close position-absolute top-0 end-0 m-4"
									>
										&nbsp;
									</button>
									<div className="modal-pos-band">
										<div className="modal-pos-band-info">
											{modalBandDataUpdate ? 
												<>
													<div className="h4 mb-2">
														Atualização de dados da Banda
													</div>
												</>
												:
												<><div className="h4 mb-2">
													Complete seu Perfil
												</div><div className="text-white text-opacity-50 mb-2">
														<div>
															Precisamos de algumas
															informações sobre sua banda para
															prosseguirmos.
															<br />
															Preencha os campos tendo em
															mente que essas informações
															poderão ser visualizadas por
															possíveis clientes.
														</div>
													</div></>}
											<hr className="mx-n4" />

											{/* Nome e email */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														<div>
															Nome da Banda
														</div> 
													</label>
													<input
														defaultValue={
															modalBandDataUpdate.name ||
															modalBandData.name
														}
														onChange={
															handleBandInputChange
														}
														name="name"
														className="form-control"
													></input>
												</div>
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														Email
													</label>
													<input
														defaultValue={
															modalBandDataUpdate.email ||
															modalBandData.email
														}
														onChange={
															handleBandInputChange
														}
														name="email"
														className="form-control"
														type="email"
													></input>
												</div>
												<div
													className="mb-2"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														CPF ou CNPJ
													</label>
													<input
														defaultValue={
															modalBandDataUpdate.document ||
															modalBandData.document
														}
														onChange={
															handleBandInputChange
														}
														name="document"
														className="form-control"
													></input>
												</div>
											</div>

											{/* Estado e Cidade */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<div className="fw-bold">
														Estado
													</div>
													<select
														key={
															modalBandData.state
														}
														onChange={
															handleBandInputChange
														}
														name="state"
														className="form-select"
														defaultValue={
															modalBandDataUpdate.state ||
															modalBandData.state
														}
													>
														<option value="AC">
															Acre
														</option>
														<option value="AL">
															Alagoas
														</option>
														<option value="AP">
															Amapá
														</option>
														<option value="AM">
															Amazonas
														</option>
														<option value="BA">
															Bahia
														</option>
														<option value="CE">
															Ceará
														</option>
														<option value="DF">
															Distrito Federal
														</option>
														<option value="ES">
															Espírito Santo
														</option>
														<option value="GO">
															Goiás
														</option>
														<option value="MA">
															Maranhão
														</option>
														<option value="MT">
															Mato Grosso
														</option>
														<option value="MS">
															Mato Grosso do Sul
														</option>
														<option value="MG">
															Minas Gerais
														</option>
														<option value="PA">
															Pará
														</option>
														<option value="PB">
															Paraíba
														</option>
														<option value="PR">
															Paraná
														</option>
														<option value="PE">
															Pernambuco
														</option>
														<option value="PI">
															Piauí
														</option>
														<option value="RJ">
															Rio de Janeiro
														</option>
														<option value="RN">
															Rio Grande do Norte
														</option>
														<option value="RS">
															Rio Grande do Sul
														</option>
														<option value="RO">
															Rondônia
														</option>
														<option value="RR">
															Roraima
														</option>
														<option value="SC">
															Santa Catarina
														</option>
														<option value="SP">
															São Paulo
														</option>
														<option value="SE">
															Sergipe
														</option>
														<option value="TO">
															Tocantins
														</option>
													</select>
												</div>
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="bandCity"
														className="fw-bold"
													>
														Cidade
													</label>
													<input
														defaultValue={
															modalBandDataUpdate.city ||
															modalBandData.city
														}
														onChange={
															handleBandInputChange
														}
														name="city"
														className="form-control"
														id="bandCity"
													></input>
												</div>
												<div
													className="mb-2"
													style={{ flex: 1 }}
												>
													<label
														htmlFor="bandCity"
														className="fw-bold"
													>
														Logradouro
													</label>
													<input
														defaultValue={
															modalBandDataUpdate.street ||
															modalBandData.street
														}
														onChange={
															handleBandInputChange
														}
														name="street"
														className="form-control"
														id="bandStreet"
													></input>
												</div>
											</div>

											{/* Telefones e foto */}

											<div className="d-flex flex-row mb-2">
												<div
													className="mb-2 me-4"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														Telefone de contato
													</label>
													<input
														defaultValue={
															modalBandDataUpdate.phone ||
															modalBandData.phone
														}
														onChange={
															handleBandInputChange
														}
														name="phone"
														className="form-control"
														id="phone"
														type="tel"
													></input>
												</div>
												<div
													className="mb-2 me-6"
													style={{ flex: 1 }}
												>
													<label className="fw-bold">
														Telefone alternativo
													</label>
													<input
														defaultValue={
															modalBandDataUpdate.phone2 ||
															modalBandData.phone2
														}
														onChange={
															handleBandInputChange
														}
														name="phone2"
														className="form-control"
														id="phone2"
														type="tel"
													></input>
												</div>
												
											</div>

                      <div>
                        {/* Sobre a Banda */}

                        <div className="d-flex flex-row mb-2">
                          <div className="mb-2 w-100">
                            <div className="fw-bold">
                              Breve Descrição da sua
                              Banda
                            </div>
                            <textarea
                              // key={modalBandData.about}
                              defaultValue={
																modalBandDataUpdate.about ||
                                modalBandData.about
                              }
                              onChange={
                                handleBandInputChange
                              }
                              rows="3"
                              name="about"
                              className="form-control"
															id="about"
                            ></textarea>
                          </div>
                        </div>

                        <div className="mb-2" style={{ flex: 1 }}>
                          <label className="fw-bold">Foto da Banda/Artista</label>
                          <input
                            onChange={uploadImage}
                            type="file" 
                            className="form-control form-control-lg" 
                            id="lgFile" 
                            accept="image/*"
                            />
                          </div>
                        {/* Estilo, cachê e pix */}

                        <div className="d-flex flex-row mb-2">
                          <div
                            className="mb-2 me-4"
                            style={{ flex: 1 }}
                          >
                            <label className="fw-bold">
                              Estilo Musical
                            </label>
                            <input
                              defaultValue={
																modalBandDataUpdate.styles ||
                                modalBandData.styles
                              }
                              onChange={
                                handleBandInputChange
                              }
                              name="styles"
                              className="form-control"
                              id="styles"
                            ></input>
                          </div>
                          <div
                            className="mb-2 me-4"
                            style={{ flex: 1 }}
                          >
                            <label className="fw-bold">
                              Valor aproximado do seu
                              cachê
                            </label>
                            <input
                              defaultValue={
																modalBandDataUpdate.cache ||
                                modalBandData.cache
                              }
                              onChange={
                                handleBandInputChange
                              }
                              name="cache"
                              className="form-control"
                              id="cache"
                              type="number"
                            ></input>
                          </div>
                          <div
                            className="mb-2"
                            style={{ flex: 1 }}
                          >
                            <label className="fw-bold">
                              Chave PIX
                            </label>
                            <input
                              defaultValue={
																modalBandDataUpdate.pix ||
                                modalBandData.pix
                              }
                              onChange={
                                handleBandInputChange
                              }
                              name="pix"
                              className="form-control"
                              id="pix"
                            ></input>
                          </div>
                        </div>	

                        {/* Dados bancários */}

                        <div className="d-flex flex-row mb-2">
                          <div
                            className="mb-2 me-4"
                            style={{ flex: 1 }}
                          >
                            <label className="fw-bold">
                              Código do banco
                            </label>
                            <input
                              defaultValue={
																modalBandDataUpdate.bank ||
                                modalBandData.bank
                              }
                              onChange={
                                handleBandInputChange
                              }
                              name="bank"
                              className="form-control"
                              id="bank"
                              type="number"
                            ></input>
                          </div>
                          <div
                            className="mb-2 me-4"
                            style={{ flex: 1 }}
                          >
                            <label className="fw-bold">
                              Agência
                            </label>
                            <input
                              defaultValue={
																modalBandDataUpdate.agency ||
                                modalBandData.agency
                              }
                              onChange={
                                handleBandInputChange
                              }
                              name="agency"
                              className="form-control"
                              id="agency"
                              type="number"
                            ></input>
                          </div>
                          <div
                            className="mb-2"
                            style={{ flex: 1 }}
                          >
                            <label className="fw-bold">
                              Número da conta
                            </label>
                            <input
                              defaultValue={
																modalBandDataUpdate.account ||
                                modalBandData.account
                              }
                              onChange={
                                handleBandInputChange
                              }
                              name="account"
                              className="form-control"
                              id="account"
                              type="number"
                            ></input>
                          </div>
                        </div>
                      </div>								

											<hr className="mx-n4" />

											<div className="row">
												<div className="">
													{bandFail && (
														<div
															style={{
																color: "#c72e2e",
															}}
														>
															Todos os campos
															devem ser
															preenchidos
														</div>
													)}
													{emailError && (
														<div
															style={{
																color: "#c72e2e",
															}}
														>
															Email cadastrado para outra banda.
														</div>
													)}
													{modalBandDataUpdate ? 
														<button
														onClick={updateBand}
														className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
													>
														Atualizar Banda
													</button>
														:
														<button
														onClick={submitBand}
														className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
													>
														Criar Nova Banda
													</button>}
												</div>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Banda;
