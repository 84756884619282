import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { POST } from '../../services/api.ts';

function PagesRegister() {
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [userType, setUserType] = useState("contractor");
	const [terms, setTerms] = useState(false);
	const [emailError, setEmailError] = useState(false);

	
	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};
		
		// eslint-disable-next-line
	}, []);
	
	async function handleSubmit(event) {
		event.preventDefault();
		const data = await POST('/user', {
			name: name,
			email: email,
			active: 1,
			phone: null,
			admin: 0,
			type: userType
		})
		if (data.error === 409) {
			setEmailError(true);
		} else {
			setRedirect(true);
		}

  }

	const handleDownload = (file) => (event) => {
		event.preventDefault();

		const pdfUrl = process.env.PUBLIC_URL +'/'+ file;

		const link = document.createElement('a');
		link.href = pdfUrl;
		link.target = '_blank';
		link.download = file;
		link.click();
	};
	
  
	if (redirect) {
		return <Navigate to='/' />;
	}
	return (
		<div className="register">
			<div className="register-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center">Cadastre-se</h1>
					<p className="text-inverse text-opacity-50 text-center">Texto opcional</p>
					<div className="mb-3">
						<label className="form-label">Nome <span className="text-danger">*</span></label>
						<input 
							type="text" 
							className="form-control form-control-lg bg-white bg-opacity-5" 
							onChange={(event) => setName(event.target.value)}
							placeholder="" />
					</div>
					<div className="mb-3">
						<label className="form-label">Endereço de Email <span className="text-danger">*</span></label>
						<input 
							type="text" 
							className="form-control form-control-lg bg-white bg-opacity-5" 
							onChange={(event) => setEmail(event.target.value)}
							placeholder="usuario@email.com" />
					</div>
					{/* <div className="mb-3">
						<label className="form-label">Password <span className="text-danger">*</span></label>
						<input type="password" className="form-control form-control-lg bg-white bg-opacity-5" />
					</div>
					<div className="mb-3">
						<label className="form-label">Confirm Password <span className="text-danger">*</span></label>
						<input type="password" className="form-control form-control-lg bg-white bg-opacity-5" />
					</div> */}
					<div className="mb-3">
						<label className="form-label">Quem é você? <span className="text-danger">*</span></label>
						<select 
							onChange={(event) => setUserType(event.target.value)}
							className="form-select form-select-lg bg-white bg-opacity-5"
							>
							<option value={'contractor'}>Contratante</option>
							{/* <option value={'band'}>Artista</option> */}
							<option value={'agent'}>Artista</option>
						</select>
					</div>
					{/* <div className="mb-3">
						<label className="form-label">Gender <span className="text-danger">*</span></label>
						<select className="form-select form-select-lg bg-white bg-opacity-5">
							<option>Female</option>
						</select>
					</div> */}
					{/* <div className="mb-3">
						<label className="form-label">Date of Birth <span className="text-danger">*</span></label>
						<div className="row gx-2">
							<div className="col-6">
								<select className="form-select form-select-lg bg-white bg-opacity-5">
									<option>Month</option>
								</select>
							</div>
							<div className="col-3">
								<select className="form-select form-select-lg bg-white bg-opacity-5">
									<option>Day</option>
								</select>
							</div>
							<div className="col-3">
								<select className="form-select form-select-lg bg-white bg-opacity-5">
									<option>Year</option>
								</select>
							</div>
						</div>
					</div> */}
					{emailError && 
						<div style={{color: "#c72e2e"}}>
						Este Email já está associado a outra conta
						</div>}
					<div className="mb-3">
						<div className="form-check">
							<input 
								className="form-check-input" 
								type="checkbox" 
								onChange={() => setTerms(!terms)}
								id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">
								Eu li e concordo com os{" "}
								<a href="#/" onClick={handleDownload('Termos de Uso.pdf')}>
									Termos de Uso
								</a>{" "}
								e{" "}
								<a href="#/" onClick={handleDownload('Política de Privacidade.pdf')}>
									Política de Privacidade
								</a>
								.
							</label>
						</div>
					</div>
					<div className="mb-3">
						<button 
							type="submit" 
							disabled={!terms || name === '' || email === ''}
							className="btn btn-outline-theme btn-lg d-block w-100">Cadastre-se</button>
					</div>
					<div className="text-inverse text-opacity-50 text-center">
						Já tem uma conta? <Link to="/pages/login">Entrar</Link>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PagesRegister;