import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { PUT } from '../../services/api.ts';

function PagesRecover() {
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	
	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};
		
		// eslint-disable-next-line
	}, []);
	
	async function handleSubmit(event) {
		event.preventDefault();
		try { await PUT(`user/recover/${email}`)
	    } catch (error) {
		 	console.log(error);
			if(error.status === 404){
				setEmailError(true);
			}
	    }	
		setRedirect(true);
		}
  
	if (redirect && !emailError) {
		return <Navigate to='/' />;
	}
	return (
		<div className="register">
			<div className="register-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center">Esqueci a senha</h1>
					<p className="text-inverse text-opacity-50 text-center">Insira seu Email para recuperar sua conta</p>
					<div className="mb-3">
						<label className="form-label">Endereço de Email <span className="text-danger">*</span></label>
						<input type="text" 
						 className="form-control form-control-lg bg-white bg-opacity-5" 
						 onChange={(event) => setEmail(event.target.value)}
						 placeholder="usuario@email.com" />
					</div>
					{emailError && 
					<div style={{color: "#c72e2e"}}>
					Este Email não está associado a uma conta
					</div>}
					<div className="mb-3">
						<button type="submit" className="btn btn-outline-theme btn-lg d-block w-100">Recuperar conta</button>
					</div>
					<div className="text-inverse text-opacity-50 text-center">
						Já tem uma conta? <Link to="/pages/login">Entrar</Link>
					</div>
				</form>
			</div>	
	   </div>
	)
}

export default PagesRecover;