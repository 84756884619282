import React from "react";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";

function CustomHeader() {
	let local = localStorage.getItem("loginInfo_access");
	let user;
	if (local === null) {
		user = {
			payload: {
				id: null,
				email: null,
				name: null,
				type: null,
				admin: null,
				bandId: null,
				asaas_validated: null,
			},
		};
	} else {
		user = {
			...JSON.parse(local).payload,
		};
	}

	return (
		<div id="header" className="app-header">
			<div className="brand">
				<Link to="/" className="brand-logo">
					<span className="brand-img">
						<span className="brand-img-text text-theme">I</span>
					</span>
					<span className="brand-text">Painel IAUÜ</span>
				</Link>
			</div>

			<div className="menu">
				<div className="menu-item dropdown dropdown-mobile-full">
					<Link
						to="/pages/login"
						className="btn-filter"
					>
						LOGIN{" "}
						<FiLogIn />
					</Link>
				</div>
			</div>
		</div>
	);
}

export default CustomHeader;
